<script>
export default {
props:['area_roles'],
}
</script>

<template>
 
    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0 table-bordered">
                <thead>
                  <tr>
                    <!-- <th class="f-14 fw-semiBold roboto">Sr. #</th> -->
                    <th class="f-14 fw-semiBold roboto">Area Role Name</th>
                    <th class="f-14 fw-semiBold roboto">Area Role Wage Rate</th>
                    <th class="f-14 fw-semiBold roboto">created_at</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(area_role,key) in area_roles" :key="key">
                    <!-- <td scope="row" class="f-14 roboto pColor">{{area_role.id}}</td> -->
                    <td scope="row" class="f-14 roboto pColor">{{area_role.name}}</td>
                    <td scope="row" class="f-14 roboto pColor">{{area_role.payment ? area_role.payment+'$' : 'N/A'}}</td>
                    <td scope="row" class="f-14 roboto pColor">{{DateWithMonthName(area_role.created_at , 'ddd, MMM DD,YYYY hh:mm A')}}</td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  
</template>
